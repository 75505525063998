import * as React from "react";
import {FC, useEffect, useRef, useState} from "react";
import {Accordion, Button, Card, Col, Form, Row, Spinner} from "react-bootstrap";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
// @ts-ignore
import * as style from "./EditVendorData.module.scss";
// @ts-ignore
import * as mainStyle from '../../theme/main.module.scss';
import UploadField from "../UploadField/UploadField";
import {Client, handleApiError} from "../../services/ApiService";
import {connect, ConnectedProps} from "react-redux";
import './dropzone.scss';
import Loading from "../Loading/Loading";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronRight, faGavel, faImagePolaroid, faUserCircle} from "@fortawesome/pro-light-svg-icons";
import {toast} from "react-toastify";
import {MultiSelect} from "react-multi-select-component";
import {setAGBs, setROW, setFotos, setHeaderImage} from "../../stores/registration/registrationSlice";
import {setLastEditedProductID} from "../../stores/member/memberSlice";

const mapState = ({member, auth, registration}) => ({
    member,
    auth,
    registration
})

const mapDispatch = {
    setFotos,
    setHeaderImage,
}


const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>


interface FormInputs {
    FirstName: string,
    Surname: string,
    Email: string,
    Password: string,
    PasswordRetype: string,
    CompanyStreet: string,
    CompanyStreetNumber: string,
    CompanyZIP: string,
    CompanyCity: string,
    CompanyLand: string,
    CompanyName: string,
    CompanyPhone: string,
    CompanyEmail: string,
    CompanyWebsite: string,
    CompanyUID: string,
    TaxNumber: string,
    CompanyShortDesc: string,
    CompanyLongDesc: string,
    AGB: string
    ROW: string
    Description: string,
    InvoiceVendorName: string,
}

const schema = yup.object().shape({
    CompanyStreet: yup.string().required('Bitte gib einen Straßennamen an.'),
    CompanyStreetNumber: yup.string().required('Bitte gib eine Hausnummer an'),
    CompanyZIP: yup.string().required('Bitte gib eine Postleitzahl an'),
    CompanyCity: yup.string().required('Bitte gib einen Ort an'),
    CompanyLand: yup.string().required('Bitte wähle ein Land aus'),
    CompanyName: yup.string().required('Bitte gib einen Firmennamen an.'),
    InvoiceVendorName: yup.string().optional(),
    CompanyPhone: yup.string().optional(),
    CompanyEmail: yup.string().optional().email('Bitte gib eine gültige E-Mail Adresse an.'),
    CompanyWebsite: yup.string().optional(),
    CompanyUID: yup.string().optional(),
    TaxNumber: yup.string().optional(),
    CompanyShortDesc: yup.string().optional(),
    CompanyLongDesc: yup.string().optional(),
    AGB: yup.string().optional(),
    ROW: yup.string().optional(),
    Description: yup.string().optional(),
})

interface Vendor {
    AGB: string,
    Address: Address,
    AddressID: number,
    CompanyName: string,
    Description: string,
    Email: string,
    ID: number,
    Images: Array<any>,
    ReturnPolicy: string,
    ShortDescription: string,
    Tel: string,
    Title: string,
    UID: string,
    URLSegment: string,
    Website: string,
    InvoiceVendorName: string
}

interface Address {
    City: string,
    Country: string,
    Number: string,
    PostalCode: number,
    StateName: string,
    Street: string,
}

const EditVendorData: FC<PropsFromRedux & any> = ({
                                                      user, member, auth, registration,
                                                      setFotos,
                                                      setHeaderImage
                                                  }) => {
    const editorRef = useRef();

    const [vendor, setVendor] = useState<Vendor>(null);
    const [categories, setCategories] = useState([]);
    const [activeKey, setActiveKey] = useState(0);
    const [open, setOpen] = useState(true);
    const [editorLoaded, setEditorLoaded] = useState(false)
    const [desc, setDesc] = useState('');
    const [ROW, setROW] = useState('')
    const [agbs, setAGBs] = useState('')
    const [selected, setSelected] = useState([]);
    const [selectionToSend, setSelectionToSend] = useState([]);
    const [loadingCompanyData, setLoadingCompanyData] = useState(false);
    const [loadingCompanyAddress, setLoadingCompanyAddress] = useState(false);
    const [loadingImage, setLoadingImage] = useState(false);
    const [loadingAGB, setLoadingAGB] = useState(false);
    const [loadingROW, setLoadingROW] = useState(false);
    const [loadingTransparency, setLoadingTransparency] = useState(false);
    const [transparency, setTransparency] = useState('');
    // @ts-ignore
    const {CKEditor, ClassicEditor} = editorRef.current || {}

    // useEffect(() => {
    //     if(vendor) {
    //         setTransparency(vendor.Transparency);
    //         setDesc(vendor.Description);
    //         console.log(transparency)
    //         console.log(desc)
    //     }
    // }, [vendor])

    useEffect(() => {
        console.log(transparency)
    }, [transparency])

    useEffect(() => {

        console.log("DO SMTH with: " +  user.VendorID)
        // @ts-ignore
        Client.Raw.get('Vendor/' + user.VendorID).then((res) => {
            setFotos(res.data.Images ?? [])
            setHeaderImage([res.data.HeaderImage] ?? [])
            setVendor(res.data)
            setAGBs(res.data.AGB)
            setTransparency(res.data.Transparency);
            setDesc(res.data.Description);
            setSelectionFromAPI(res.data.Categories);

            console.log("data from vendor")
            console.log(res.data)

        }).catch(handleApiError);

        Client.Raw.get('Category').then((res) => {
            res.data.forEach((cat) => {
                categories.push({
                    label: cat.Title,
                    value: cat.ID
                })
            })
        }).catch(handleApiError)
    }, [])


    useEffect(() => {
        console.log("changed Desc")

            console.log("desc if true")
            // @ts-ignore
            editorRef.current = {
                // CKEditor: require('@ckeditor/ckeditor5-react'), // depricated in v3
                CKEditor: require('@ckeditor/ckeditor5-react').CKEditor, // v3+
                ClassicEditor: require('@ckeditor/ckeditor5-build-classic')
            }
            setEditorLoaded(true)

    }, [desc])

    useEffect(() => {
        Client.Raw.get('Vendor/' + user.VendorID).then((res) => setVendor(res.data)).catch(handleApiError);
    }, [user])

    const {register, handleSubmit, formState: {errors}} = useForm<FormInputs>({
        resolver: yupResolver(schema)
    });

    function setSelectionFromAPI(cats) {
        let temp = [];
        cats.forEach((cat) => {
            temp.push({
                label: cat.Title,
                value: cat.ID
            })
        })
        setSelected(temp);
    }

    function onSubmitCompanyData(data) {
        setLoadingCompanyData(true)
        Client.Vendor.postData(auth.Key, user.VendorID, {
            CompanyName: data.CompanyName,
            InvoiceVendorName: data.InvoiceVendorName,
            Tel: data.CompanyPhone,
            UID: data.CompanyUID,
            TaxNumber: data.TaxNumber,
            Email: data.CompanyEmail,
            Website: data.CompanyWebsite,
            Description: desc
        }).then(() => {
            Client.Auth.authPatch(auth.Key, 'Vendor', member.Member.VendorID, selectionToSend).then((res) => {
                toast.success('Erfolgreich aktualisiert!');
                setLoadingCompanyData(false)
            }).catch(handleApiError);
        }).catch(handleApiError);
    }

    function onSubmitCompanyAddress(data) {
        setLoadingCompanyAddress(true)
        if (vendor.AddressID !== 0) {
            Client.Auth.authPut(auth.Key, 'Address', vendor.AddressID, {
                PostalCode: data.CompanyZIP,
                City: data.CompanyCity,
                Street: data.CompanyStreet,
                Number: data.CompanyStreetNumber,
                Country: data.CompanyLand,
            }).then((res) => {

                if(data.InvoiceVendorName) {
                    Client.Vendor.postData(auth.Key, user.VendorID, {
                        InvoiceVendorName: data.InvoiceVendorName,
                    }).then(() => {
                        Client.Auth.authPatch(auth.Key, 'Vendor', member.Member.VendorID, selectionToSend).then((res) => {
                            toast.success('Erfolgreich aktualisiert!');
                            setLoadingCompanyAddress(false)
                        }).catch(handleApiError);
                    }).catch(handleApiError);
                } else {
                    toast.success('Erfolgreich aktualisiert!');
                    setLoadingCompanyAddress(false)
                }
            }).catch((error) => {
                handleApiError(error);
                setLoadingCompanyAddress(false);
            });
        } else {
            Client.Auth.authPostNoID(auth.Key, 'Address', {
                PostalCode: data.CompanyZIP,
                City: data.CompanyCity,
                Street: data.CompanyStreet,
                Number: data.CompanyStreetNumber,
                Country: data.CompanyLand,
            }).then((res) => {
                toast.success('Erfolgreich aktualisiert!');
                Client.Auth.authPut(auth.Key, 'Vendor', member.Member.VendorID, {
                    AddressID: res.data.ID
                }).catch(handleApiError)
                setLoadingCompanyAddress(false)
            }).catch((error) => {
                handleApiError(error);
                setLoadingCompanyAddress(false);
            });
        }
    }

    function onImageSubmit(data) {
        setLoadingImage(true)

        Client.Vendor.postData(auth.Key, user.VendorID, {
            HeaderImageID: registration.headerImage[0]?.ID
        }).then((res) => {
            if (registration.fotos.length) {
                Client.Vendor.removeImages(auth.Key, user.VendorID).then(() => {
                    Client.Vendor.addImages(auth.Key, res.data.ID, registration.fotos).then(() => {
                        setLoadingImage(false)
                        toast.success('Händlerbilder erfolgreich aktualisiert!')
                    }).catch(handleApiError);
                })
            }
        }).catch(handleApiError)
    }

    function onSubmitCompanyLegalAGB(data) {
        setLoadingAGB(true)
        Client.Vendor.postData(auth.Key, user.VendorID, {
            AGB: agbs
        }).then(() => {
            toast.success('Erfolgreich aktualisiert!');
            setLoadingAGB(false)
        }).catch(handleApiError)
    }

    function onSubmitCompanyLegalROW(data) {
        setLoadingROW(true)
        Client.Vendor.postData(auth.Key, user.VendorID, {
            ReturnPolicy: ROW
        }).then(() => {
            toast.success('Erfolgreich aktualisiert!');
            setLoadingROW(false)
        }).catch(handleApiError);
    }

    function onTransparencySubmit(data) {
        setLoadingTransparency(true)
        Client.Auth.authPut(auth.Key, 'Vendor', vendor.ID, {
            Transparency: transparency
        }).then(() => {
            toast.success('Erfolgreich aktualisiert!');
            setLoadingTransparency(false)
        }).catch(handleApiError)
    }


    useEffect(() => {
        let temp = [];
        selected.forEach((selection) => {
            temp.push({
                'ID': selection.value,
                'ClassName': "CIC\\App\\Category"
            });
        });
        setSelectionToSend(temp);
    }, [selected])

    return (
        <>
            {vendor && vendor.ID ?
                <Row>
                    <h2 className={style.mainHeading}>Händlerdaten</h2>
                    <Accordion defaultActiveKey="0" className={"mb-5"}>
                        <Card className={style.card}>
                            <Accordion.Toggle as={Card.Header} className={style.cardHeader} eventKey="0"
                                              onClick={() => {
                                                  setActiveKey(activeKey === 0 ? 9999 : 0)
                                                  setOpen(!open)
                                              }}>
                                <div>
                                    <FontAwesomeIcon icon={faUserCircle} className={style.tabIcon}/> Allgemeine Daten
                                </div>
                                <div className={style.chevron}>
                                    {activeKey === 0 ?
                                        <FontAwesomeIcon icon={faChevronDown}/> :
                                        <FontAwesomeIcon icon={faChevronRight}/>
                                    }
                                </div>
                            </Accordion.Toggle>
                            <Accordion.Collapse className={style.cardCollapse} eventKey="0">
                                <Row>
                                    <Col lg={6}>
                                        <Form onSubmit={handleSubmit(onSubmitCompanyData)} className={style.form}>
                                            <h2 className={style.subHeading}>Firmendaten</h2>
                                            <Row>
                                                <Col lg={6}>
                                                    <Form.Group controlId="formFirstName" className="mb-2">
                                                        <Form.Label className={style.inputLabel}>Firmenname</Form.Label>
                                                        <Form.Control type="text" className={style.inputField}
                                                                      defaultValue={vendor ? vendor.CompanyName : ''}
                                                                      {...register('CompanyName')}/>
                                                        <Form.Text className={mainStyle.errorMsg}>
                                                            {errors.CompanyName?.message}
                                                        </Form.Text>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={6}>
                                                    <Form.Group controlId="formLastName">
                                                        <Form.Label
                                                            className={style.inputLabel}>Telefonnummer</Form.Label>
                                                        <Form.Control type="text" className={style.inputField}
                                                                      defaultValue={vendor ? vendor.Tel : ''}
                                                                      {...register('CompanyPhone')} />
                                                        <Form.Text className={mainStyle.errorMsg}>
                                                            {errors.CompanyPhone?.message}
                                                        </Form.Text>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={6}>
                                                    <Form.Group controlId="formEmail">
                                                        <Form.Label className={style.inputLabel}>UID</Form.Label>
                                                        <Form.Control type="text" className={style.inputField}
                                                                      defaultValue={vendor ? vendor.UID : ''}
                                                                      {...register('CompanyUID')}/>
                                                        <Form.Text className={mainStyle.errorMsg}>
                                                            {errors.CompanyPhone?.message}
                                                        </Form.Text>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={6}>
                                                    <Form.Group controlId="formPassword">
                                                        <Form.Label className={style.inputLabel}>E-Mail</Form.Label>
                                                        <Form.Control type="text"
                                                                      defaultValue={vendor ? vendor.Email : ''}
                                                                      className={style.inputField}{...register('CompanyEmail')}/>
                                                        <Form.Text className={mainStyle.errorMsg}>
                                                            {errors.CompanyEmail?.message}
                                                        </Form.Text>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={6}>
                                                    <Form.Group controlId="formEmail">
                                                        <Form.Label className={style.inputLabel}>(Kleinunternehmen) Steuer-Nummer</Form.Label>
                                                        <Form.Control type="text" className={style.inputField}
                                                                      defaultValue={vendor ? vendor.TaxNumber : ''}
                                                                      {...register('TaxNumber')}/>
                                                        <Form.Text className={mainStyle.errorMsg}>
                                                            {errors.CompanyPhone?.message}
                                                        </Form.Text>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={6}>
                                                    <Form.Group controlId="formPasswordRetype">
                                                        <Form.Label className={style.inputLabel}>Kategorie</Form.Label>
                                                        {/*<Form.Control as="select" className={style.dropDown}*/}
                                                        {/*              onChange={(event) => console.log(event.target.value)} {...register('CompanyCategory')}*/}
                                                        {/*                defaultValue={}*/}
                                                        {/*>*/}
                                                        {/*    <option>-- Bitte auswählen --</option>*/}
                                                        {/*    {categories.map((cat, index) => {*/}
                                                        {/*        return (*/}
                                                        {/*            <option value={cat.ID} key={index}>*/}
                                                        {/*                {cat.Title}*/}
                                                        {/*            </option>*/}
                                                        {/*        )*/}
                                                        {/*    })}*/}
                                                        {/*</Form.Control>*/}
                                                        <MultiSelect
                                                            className={style.dropDown}
                                                            options={categories}
                                                            value={selected}
                                                            onChange={setSelected}
                                                            labelledBy="Kategorieauswahl"
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                {/*<Col lg={12}>*/}
                                                {/*    <Form.Group controlId="formPasswordRetype">*/}
                                                {/*        <Form.Label*/}
                                                {/*            className={style.inputLabel}>Kurzbeschreibung</Form.Label>*/}
                                                {/*        <Form.Control as="textarea" rows={3}*/}
                                                {/*                      className={style.textArea}  {...register('CompanyShortDesc')}*/}
                                                {/*                      defaultValue={vendor ? vendor.ShortDescription : ''}/>*/}
                                                {/*        <Form.Text className={mainStyle.errorMsg}>*/}
                                                {/*            {errors.CompanyShortDesc?.message}*/}
                                                {/*        </Form.Text>*/}
                                                {/*    </Form.Group>*/}
                                                {/*</Col>*/}
                                                <Col lg={12}>
                                                    <Form.Group controlId="formLongDesc">
                                                        <Form.Label
                                                            className={style.inputLabel}>Beschreibung</Form.Label>
                                                        {/*<Form.Control as="textarea" rows={8}*/}
                                                        {/*              className={style.textArea}  {...register('CompanyLongDesc')}*/}
                                                        {/*              defaultValue={vendor ? vendor.Description : ''}/>*/}
                                                        {desc ?
                                                            <CKEditor
                                                                editor={ClassicEditor}
                                                                config={{removePlugin: ['ImageUpload']}}
                                                                data={desc}
                                                                onChange={(event, editor) => {
                                                                    const data = editor.getData();
                                                                    setDesc(data);
                                                                }}
                                                            /> : <Loading type={"border"}
                                                                          message={"Editor wird geladen."}/>
                                                        }
                                                        <Form.Text className={mainStyle.errorMsg}>
                                                            {errors.CompanyLongDesc?.message}
                                                        </Form.Text>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={12}>
                                                    <Button type={"submit"} className={style.submitButton}
                                                            disabled={loadingCompanyData}>
                                                        {loadingCompanyData ?
                                                            <div
                                                                className={"d-flex align-items-center justify-content-center"}>
                                                                <Spinner animation={"border"}/>
                                                                <span className={"mx-3"}>Wird gespeichert</span>
                                                            </div> :
                                                            <span>
                                                               Speichern
                                                            </span>
                                                        }
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                    <Col lg={6}>
                                        <Form onSubmit={handleSubmit(onSubmitCompanyAddress)} className={style.form}>
                                            <h2 className={style.subHeading}>Firmenadresse</h2>
                                            <Row>
                                                <Col lg={10}>
                                                    <Form.Group controlId="formStreet" className="mb-2">
                                                        <Form.Label className={style.inputLabel}>Straße*</Form.Label>
                                                        <Form.Control type="text" className={style.inputField}
                                                                      defaultValue={vendor ? vendor.Address.Street : ''}
                                                                      {...register('CompanyStreet')}/>
                                                        <Form.Text className="errorMsg">
                                                            {errors.CompanyStreet?.message}
                                                        </Form.Text>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={2}>
                                                    <Form.Group controlId="formHouseNr">
                                                        <Form.Label
                                                            className={style.inputLabel}>Hausnummer*</Form.Label>
                                                        <Form.Control type="text"
                                                                      defaultValue={vendor ? vendor.Address.Number : ''}
                                                                      className={style.inputField}
                                                                      {...register('CompanyStreetNumber')}/>
                                                        <Form.Text className="errorMsg">
                                                            {errors.CompanyStreetNumber?.message}
                                                        </Form.Text>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={3}>
                                                    <Form.Group controlId="formZip">
                                                        <Form.Label className={style.inputLabel}>PLZ*</Form.Label>
                                                        <Form.Control type="text" className={style.inputField}
                                                                      defaultValue={vendor ? vendor.Address.PostalCode : ''}
                                                                      {...register('CompanyZIP')}/>
                                                        <Form.Text className="errorMsg">
                                                            {errors.CompanyZIP?.message}
                                                        </Form.Text>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={9}>
                                                    <Form.Group controlId="formOrt">
                                                        <Form.Label className={style.inputLabel}>Ort*</Form.Label>
                                                        <Form.Control type="text" className={style.inputField}
                                                                      defaultValue={vendor ? vendor.Address.City : ''}
                                                                      {...register('CompanyCity')}/>
                                                        <Form.Text className="errorMsg">
                                                            {errors.CompanyCity?.message}
                                                        </Form.Text>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={6}>
                                                    <Form.Group controlId="formRegion">
                                                        <Form.Label className={style.inputLabel}>Land*</Form.Label>
                                                        <div className={style.dropDownChevron}>
                                                            <Form.Control as="select"
                                                                          defaultValue={vendor ? vendor.Address.Country : ''}
                                                                          className={style.dropDown} {...register('CompanyLand')}>
                                                                <option value={"AT"}> Österreich</option>
                                                            </Form.Control>
                                                            <Form.Text className="errorMsg">
                                                                {errors.CompanyLand?.message}
                                                            </Form.Text>
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={6}>
                                                    <Form.Group controlId="formExtraName">
                                                            <Form.Label className={style.inputLabel}><small>(Kleinunternehmen)</small> Name</Form.Label>
                                                            <Form.Control type="text" className={style.inputField}
                                                                          defaultValue={vendor ? vendor.InvoiceVendorName : ''}
                                                                          {...register('InvoiceVendorName')}/>
                                                            <Form.Text className="errorMsg">
                                                                {errors.InvoiceVendorName?.message}
                                                            </Form.Text>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={12}>
                                                    <Button type={"submit"} className={style.submitButton}
                                                            disabled={loadingCompanyAddress}>
                                                        {loadingCompanyAddress ?
                                                            <div
                                                                className={"d-flex align-items-center justify-content-center"}>
                                                                <Spinner animation={"border"}/>
                                                                <span className={"mx-3"}>Wird gespeichert</span>
                                                            </div> :
                                                            <span>
                                                               Speichern
                                                            </span>
                                                        }
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                </Row>
                            </Accordion.Collapse>
                        </Card>
                        <Card className={style.card}>
                            <Accordion.Toggle as={Card.Header} className={style.cardHeader} eventKey="1"
                                              onClick={() => {
                                                  setActiveKey(activeKey === 1 ? 9999 : 1)
                                                  setOpen(!open)
                                              }}>
                                <div>
                                    <FontAwesomeIcon icon={faImagePolaroid} className={style.tabIcon}/> Bilder
                                </div>
                                <div className={style.chevron}>
                                    {activeKey === 1 ?
                                        <FontAwesomeIcon icon={faChevronDown}/> :
                                        <FontAwesomeIcon icon={faChevronRight}/>
                                    }
                                </div>
                            </Accordion.Toggle>
                            <Accordion.Collapse className={style.cardCollapse} eventKey="1">
                                <Card.Body>
                                    <Row>
                                        <Col lg={12} xs={12}>
                                            <Form className={style.form} onSubmit={handleSubmit(onImageSubmit)}>
                                                <h2 className={style.subHeading}>Header-Bild</h2>
                                                <UploadField maxFiles={1}/>
                                                <h2 className={style.subHeading}>Weitere Bilder</h2>
                                                <UploadField/>
                                                <Button type={"submit"} className={style.submitButton}
                                                        disabled={loadingImage}>
                                                    {loadingImage ?
                                                        <div
                                                            className={"d-flex align-items-center justify-content-center"}>
                                                            <Spinner animation={"border"}/>
                                                            <span className={"mx-3"}>Wird gespeichert</span>
                                                        </div> :
                                                        <span>
                                                               Speichern
                                                            </span>
                                                    }
                                                </Button>
                                            </Form>

                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card className={style.card}>
                            <Accordion.Toggle as={Card.Header} className={style.cardHeader} eventKey="2"
                                              onClick={() => {
                                                  setActiveKey(activeKey === 2 ? 9999 : 2)
                                                  setOpen(!open)
                                              }}>
                                <div>
                                    <FontAwesomeIcon icon={faGavel} className={style.tabIcon}/> Rechtliches
                                </div>
                                <div className={style.chevron}>
                                    {activeKey === 2 ?
                                        <FontAwesomeIcon icon={faChevronDown}/> :
                                        <FontAwesomeIcon icon={faChevronRight}/>
                                    }
                                </div>
                            </Accordion.Toggle>
                            <Accordion.Collapse className={style.cardCollapse} eventKey="2">
                                <Card.Body>
                                    <Row>
                                        <Col lg={6} xs={12}>
                                            <Form onSubmit={handleSubmit(onSubmitCompanyLegalAGB)}
                                                  className={style.form}>
                                                <h2 className={style.subHeading}>Rechtliches - AGB</h2>
                                                <Row>
                                                    {editorLoaded ?
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            config={{removePlugin: ['ImageUpload']}}
                                                            data={vendor ? vendor.AGB : ''}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                setAGBs(data);
                                                            }}
                                                        /> : <Loading type={"border"}
                                                                      message={"Editor wird geladen."}/>
                                                    }
                                                    <Col lg={12}>
                                                        <Button type={"submit"} className={style.submitButton}
                                                                disabled={loadingAGB}>
                                                            {loadingAGB ?
                                                                <div
                                                                    className={"d-flex align-items-center justify-content-center"}>
                                                                    <Spinner animation={"border"}/>
                                                                    <span className={"mx-3"}>Wird gespeichert</span>
                                                                </div> :
                                                                <span>
                                                               Speichern
                                                            </span>
                                                            }
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Col>
                                        <Col lg={6} xs={12}>
                                            <Form className={style.form}
                                                  onSubmit={handleSubmit(onSubmitCompanyLegalROW)}>
                                                <h2 className={style.subHeading}>Rechtliches - Widerrufsrecht</h2>
                                                <Row>
                                                    {editorLoaded ?
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            config={{removePlugin: ['ImageUpload']}}
                                                            data={vendor ? vendor.ReturnPolicy : ''}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                setROW(data);
                                                            }}
                                                        /> : <Loading type={"border"}
                                                                      message={"Editor wird geladen."}/>
                                                    }
                                                    <Col lg={12}>
                                                        <Button type={"submit"} className={style.submitButton}
                                                                disabled={loadingROW}>
                                                            {loadingROW ?
                                                                <div
                                                                    className={"d-flex align-items-center justify-content-center"}>
                                                                    <Spinner animation={"border"}/>
                                                                    <span className={"mx-3"}>Wird gespeichert</span>
                                                                </div> :
                                                                <span>
                                                               Speichern
                                                            </span>}
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Form>

                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card className={style.card}>
                            <Accordion.Toggle as={Card.Header} className={style.cardHeader} eventKey="3"
                                              onClick={() => {
                                                  setActiveKey(activeKey === 3 ? 9999 : 3)
                                                  setOpen(!open)
                                              }}>
                                <div>
                                    <FontAwesomeIcon icon={faImagePolaroid} className={style.tabIcon}/> Transparenz
                                </div>
                                <div className={style.chevron}>
                                    {activeKey === 3 ?
                                        <FontAwesomeIcon icon={faChevronDown}/> :
                                        <FontAwesomeIcon icon={faChevronRight}/>
                                    }
                                </div>
                            </Accordion.Toggle>
                            <Accordion.Collapse className={style.cardCollapse} eventKey="3">
                                <Card.Body>
                                    <Row>
                                        <Col lg={12} xs={12}>
                                            <Form className={style.form} onSubmit={handleSubmit(onTransparencySubmit)}>
                                                <Form.Group controlId="formCompanyName" className="mb-2">
                                                    <Form.Label
                                                        className={style.inputLabel}>Transparenz</Form.Label>
                                                    {transparency ?
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            config={{removePlugin: ['ImageUpload']}}
                                                            data={transparency}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                setTransparency(data);
                                                            }}
                                                        /> : <Loading type={"border"}
                                                                      message={"Editor wird geladen."}/>
                                                    }
                                                    <Form.Text className={mainStyle.errorMsg}>
                                                        {errors.Description?.message}
                                                    </Form.Text>
                                                </Form.Group>
                                                <Button type={"submit"} className={style.submitButton}
                                                        disabled={loadingTransparency}>
                                                    {loadingTransparency ?
                                                        <div
                                                            className={"d-flex align-items-center justify-content-center"}>
                                                            <Spinner animation={"border"}/>
                                                            <span className={"mx-3"}>Wird gespeichert</span>
                                                        </div> :
                                                        <span>
                                                               Speichern
                                                            </span>
                                                    }
                                                </Button>
                                            </Form>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </Row>
                : <Loading type={"border"} message={"Daten werden geladen"}/>
            }

        </>
    )
}

export default connector(EditVendorData);
